import { isObject } from '@slido/core';

import { RequestError } from './errors';
import type { HttpEngine, Logger } from './interfaces';
import type { RequestConfigFull } from './request';
import { Response } from './response';

type FetchResponse = globalThis.Response;

export class FetchHttpEngine implements HttpEngine {
  constructor(private logger?: Logger) {}

  async request<T>(config: RequestConfigFull): Promise<Response<T>> {
    const url = new URL(config.url);

    // Put query params in the request URL
    Object.entries(config.params ?? {})
      .filter((objectEntry) => objectEntry[1] != null)
      .forEach(([key, value]) => url.searchParams.append(key, String(value)));

    // Prepare request init
    const requestInit = this.prepareRequest(config);

    // Do the request
    const rawResponse = await this.safeFetch(url.href, requestInit, config);

    // Process response
    const response = new Response<T>({
      config,
      data: await this.tryParsingBody(rawResponse),
      headers: rawResponse.headers,
      status: rawResponse.status,
    });

    if (response.status < 200 || response.status >= 300) {
      throw new RequestError({ config, response });
    }
    return response;
  }

  private prepareRequest(requestConfig: RequestConfigFull): RequestInit {
    const headers = new Headers(requestConfig.headers);

    // Build body
    const isContentTypeReady = headers.get('content-type') != null || requestConfig.data instanceof FormData;
    const body =
      requestConfig.data != null
        ? isContentTypeReady
          ? // Leave body and headers as they are if content-type is set or using FormData
            (requestConfig.data as BodyInit)
          : // Otherwise use JSON
            JSON.stringify(requestConfig.data)
        : null;

    if (body != null && !isContentTypeReady) {
      headers.set('content-type', 'application/json');
    }
    return {
      body,
      credentials: requestConfig.withCredentials ? 'include' : undefined,
      headers,
      method: requestConfig.method.toUpperCase(),
      signal: requestConfig.abort?.signal,
    };
  }

  private async safeFetch(
    requestUrl: string,
    requestInit: RequestInit,
    requestConfig: RequestConfigFull,
  ): Promise<FetchResponse> {
    try {
      return await fetch(requestUrl, requestInit);
    } catch (error) {
      if (!looksLikeNetworkError(error)) {
        // We don't have a reliable way how to tell if it is a network error :/
        this.logger?.warn('Request failed', error);
      }
      throw new RequestError({
        config: requestConfig,
        response: new Response({
          config: requestConfig,
          data: null,
          headers: new Headers(),
          status: RequestError.NETWORK_ERROR,
        }),
      });
    }
  }

  private async tryParsingBody<T>(rawResponse: FetchResponse): Promise<T> {
    const contentType = rawResponse.headers.get('content-type');
    const contentLength = rawResponse.headers.get('content-length');

    if (contentType?.includes('application/json') && contentLength !== '0') {
      try {
        return (await rawResponse.json()) as T;
      } catch (error) {
        this.logger?.warn('Error occurred while parsing response body');
      }
    }
    return null as T;
  }
}

function looksLikeNetworkError(error: unknown) {
  return isObject(error) && error.message === 'Failed to fetch';
}
