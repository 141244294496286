export interface PollDataModel {
  active: boolean;
  dateActive?: string | null;
  dateCreated: string;
  dateUpdated: string;
  eventUuid: string;
  isQuiz: boolean;
  leaderboard?: LeaderboardStatus;
  order: number;
  presentationUuid: string;
  questions: PollQuestionDataModel[];
  results: {
    participantCount?: number;
  };
  sectionUuid: string;
  showResults: boolean;
  speakerUuid: string;
  title?: string | null;
  uuid: string;
  votingLocked: boolean;
}

export enum PollQuestionInputType {
  MultipleChoice = 'MultipleChoice',
  OpenText = 'OpenText',
  Ranking = 'Ranking',
  Rating = 'Rating',
}

export enum LeaderboardStatus {
  Final = 'final',
  Interim = 'interim',
  Off = 'off',
}

export enum PollResultsDisplayType {
  HorizontalBars = 'HorizontalBars',
  HorizontalBarsAvg = 'HorizontalBarsAvg',
  HorizontalBarsCount = 'HorizontalBarsCount',
  VerticalBars = 'VerticalBars',
  VerticalBarsCount = 'VerticalBarsCount',
  VotesList = 'VotesList',
  Wordcloud = 'Wordcloud',
}

export interface PollQuestionTimerDataModel {
  enabled: boolean;
  expiresAfter?: number;
  pingAfter?: number;
  votingTime: number;
  warmUpTime?: number; // The `ping-timer` endpoint should be called after this duration
}

export enum ImageLayoutType {
  Simple = 'Simple',
  Split = 'Split',
}

export interface RatingLabel {
  highScore?: string | null;
  lowScore?: string | null;
}

export interface PollQuestionDataModel {
  active: boolean;
  allowMultipleVotes?: boolean;
  correctAnswers?: {
    allow: boolean;
    show: boolean;
  };
  description: string | null;
  image?: PollQuestionImageDataModel;
  imageLayout?: ImageLayoutType;
  inputType: PollQuestionInputType;
  isSurveyQuestionRequired?: boolean;
  maxRating?: number;
  maxSelectableOptions?: number | null;
  options?: PollQuestionOptionDataModel[];
  order: number;
  pollUuid: string;
  randomizeOptions?: boolean;
  rankingPoolSize?: number | null;
  ratingLabel?: RatingLabel;
  renderAs?: MultipleChoicePollRenderAs;
  results: {
    avgRatingScore?: number;
    displayType: PollResultsDisplayType;
    participantCount?: number;
    votes?: PollQuestionVoteDataModel[];
    votesCount?: number;
    wordcloudData?: Array<{
      text: string;
      votesCount: number;
      weight: number;
    }>;
  };
  showAsEmojis?: boolean;
  showLeaderboardAfter?: boolean;
  showParticipantNames?: boolean;
  timer: PollQuestionTimerDataModel;
  title: string;
  uuid: string;
}

export enum MultipleChoicePollRenderAs {
  Bars = 'Bars',
  Pie = 'Pie',
}

export interface PollQuestionOptionDataModel {
  isCorrect?: boolean;
  label: string;
  order: number;
  pollQuestionUuid: string;
  rank?: number;
  uuid: string;
  votesAverageScore?: number;
  votesCount?: number;
  votesPercentage?: number;
}

export interface PollQuestionVoteDataModel {
  count?: number;
  percentage?: number;
  rating?: number;
  text?: string;
  user?: {
    avatar?: string;
    name?: string;
  };
}

export enum ThirdPartyImgSource {
  GIPHY = 'giphy',
  PEXELS = 'pexels',
}

export interface PollQuestionImageDataModel {
  thirdPartyImage?: ThirdPartyImageShape;
  upload?: {
    uuid: string;
  };
  /** @deprecated use  */
  uuid?: string | null;
}

export interface ThirdPartyImageShape {
  source: ThirdPartyImgSource;
  urlLarge: string;
  urlMedium: string;
  urlSmall: string;
}

export enum PollQuestionImageSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum PollResponseErrorCodes {
  PollDisabledForLargeEventsError = 'PollDisabledForLargeEventsError',
  QuizQuestionLimitReached = 'QuizQuestionLimitReached',
}

export interface AiQuizPayload {
  n_questions?: number;
  topic?: string;
}

export interface AiQuizQuestionResponse {
  options: Array<{ is_correct: boolean; text: string }>;
  text: string;
}
