import type { RequestConfigFull } from './request';

export class Response<T> {
  config: RequestConfigFull;
  data: T;
  headers: Headers;
  status: number;

  constructor({
    config,
    data,
    headers,
    status,
  }: {
    config: RequestConfigFull;
    data: T;
    headers: Headers;
    status: number;
  }) {
    this.config = config;
    this.data = data;
    this.headers = headers;
    this.status = status;
  }
}
