export enum UserConsentType {
  CollectCompanyName = 'CollectCompanyName',
  CollectEmail = 'CollectEmail',
  CollectName = 'CollectName',
  StoreAdvertisingCookies = 'StoreAdvertisingCookies',
  StoreAnalyticalCookies = 'StoreAnalyticalCookies',
  StoreEssentialCookies = 'StoreEssentialCookies',

  StoreUnessentialCookies = 'StoreUnessentialCookies', // Deprecated
}

export interface UserConsentDataModel {
  consent_name: UserConsentType;
}

export interface UserConsentsChangeDataModel {
  extra?: {
    [propName: string]: unknown;
    app_id?: string;
    app_subcomponent?: string;
    app_version?: string;
  };
  granted_consents?: UserConsentType[];
  revoked_consents?: UserConsentType[];
}
