export enum StreamMessageType {
  ApiAccountEventPrivacySettingsApplyToAllEventsFinished = 'Api.AccountEventPrivacySettings.ApplyToAllEventsFinished',
  ApiAnnouncementCreate = 'Api.Announcement.create',
  ApiAnnouncementUpdate = 'Api.Announcement.update',
  ApiEventPollV2Created = 'Api.EventPollV2Created',
  ApiEventPollV2Deleted = 'Api.EventPollV2Deleted',
  ApiEventPollV2Updated = 'Api.EventPollV2Updated',
  ApiEventPollVotesTopicsUpdated = 'Api.PollTopics.Update',
  ApiParticipantsForceLogout = 'Api.Participants.ApiForceLogout',
  ApiPresentationsIntegrationPresentationUpdated = 'Api.PresentationsIntegration.PresentationUpdated',
  ApiPresentationsIntegrationSlideDeleted = 'Api.PresentationsIntegration.SlideDeleted',
  ApiPresentationsIntegrationSlideUpdated = 'Api.PresentationsIntegration.SlideUpdated',
  ApiPresentationsIntegrationSlidesBulkCreated = 'Api.PresentationsIntegration.SlidesBulkCreated',
  ApiUserForceLogout = 'Api.User.ApiUserForceLogout',
  ApiWebexEventDisconnected = 'Api.WebexEventDisconnected',
  ApiWebexMeetingConnectedToSlidoEvent = 'Api.WebexMeetingConnectedToSlidoEvent',
  ApiWebexSessionUpdated = 'Api.WebexSessionUpdated',
  ApiZoomEventDisconnected = 'Api.ZoomEventDisconnected',
  ApiZoomMeetingConnectedToSlidoEvent = 'Api.ZoomMeetingConnectedToSlidoEvent',
  EventSectionsUpdate = 'event.sections.update',
  EventUpdate = 'event.update',
  EventsListEventDelete = 'eventsList.event.delete',
  EventsListEventInsert = 'eventsList.event.insert',
  EventsListEventPatch = 'eventsList.event.patch',
  ExportFinished = 'export.finished',
  FeedbackResultsDelete = 'feedback.results.delete',
  FeedbackResultsPatch = 'feedback.results.patch',
  FunIndicatorEmojiVote = 'slido.event.fun.emoji-vote',
  FunIndicatorUpdate = 'slido.event.fun.realFun',
  IntegrationsEventAccessRequestApproved = 'integrations.event.accessRequest.approved',
  IntegrationsEventAccessRequestCreated = 'integrations.event.accessRequest.created',
  IntegrationsEventAccessRequestDeclined = 'integrations.event.accessRequest.declined',
  IntegrationsWebexEventsStudioChatMessageCreated = 'integrations.webexEventsStudio.chatMessage.created',
  IntegrationsWebexEventsStudioReactionCreated = 'integrations.webexEventsStudio.reaction.created',
  IntegrationsWebexSessionEnded = 'integrations.webex.session.ended',
  IntegrationsWebexSessionRequestStop = 'integrations.webex.session.requestStop',
  IntegrationsWebexSessionStarted = 'integrations.webex.session.started',
  NewQuestion = 'newQuestion',
  PollReset = 'poll.reset',
  PresentationCreate = 'presentation.create',
  PresentationDelete = 'presentation.delete',
  PresentationUpdate = 'presentation.update',
  SaAccessPolicyCreated = 'saAccessPolicy.Created',
  SaAccessPolicyDeclined = 'saAccessPolicy.Declined',
  SaAccessPolicyUpdated = 'saAccessPolicy.Updated',
  SlidesdriveEventSwitcherInstanceCreate = 'slidesdrive.event.switcherInstance.create',
  SlidesdriveEventSwitcherInstanceDelete = 'slidesdrive.event.switcherInstance.delete',
  SlidesdriveEventSwitcherInstanceOnlineStatusUpdate = 'slidesdrive.event.switcherInstance.onlineStatusUpdate',
  SlidesdriveEventSwitcherInstanceUpdate = 'slidesdrive.event.switcherInstance.update',
  SlidoEventAnalyticsUpdate = 'slido.event.analytics.update',
  SlidoEventLabelDestroy = 'slido.event.label.destroy',
  SlidoEventLabelUpdate = 'slido.event.label.update',
  SlidoEventQuestionAdminAction = 'slido.event.question.admin.action',
  SlidoEventQuestionDestroy = 'slido.event.question.destroy',
  SlidoEventQuestionPatch = 'slido.event.question.patch',
  SlidoEventQuestionTopicsUpdate = 'slido.event.question.topics.update',
  SlidoEventQuestionUpdate = 'slido.event.question.update',
  SlidoEventQuizJoin = 'slido.event.quiz.join',
  SlidoEventQuizJoinPatch = 'slido.event.quiz.join.patch',
  SlidoEventQuizLeaderboard = 'slido.event.quiz.leaderboard',
  SlidoEventStatisticsUpdate = 'slido.event.statistics.update',
  SlidoEventSummaryUpdate = 'slido.event.summary.update',
  SlidoEventTypingIndicatorUpdate = 'slido.event.typingIndicatorUpdate',
  SlidoIdeaTopicCreate = 'slido.ideaTopic.create',
  SlidoIdeaTopicDelete = 'slido.ideaTopic.delete',
  SlidoIdeaTopicUpdate = 'slido.ideaTopic.update',
  WallStatus = 'wall.status',
}
