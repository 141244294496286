import type { RequestConfigFull } from './request';
import type { Response } from './response';

/**
 * **NOTE**:
 * * `status: RequestError.UNKNOWN_ERROR` ('Number.NEGATIVE_INFINITY') marks an unknown status.
 * * `status: RequestError.NETWORK_ERROR` (`-1`) marks a network error (**please** use it accordingly).
 */
export class RequestError<T = unknown> extends Error {
  static NETWORK_ERROR = -1;
  static UNKNOWN_ERROR = Number.NEGATIVE_INFINITY;

  readonly name = 'RequestError';
  readonly config: RequestConfigFull;
  readonly response?: Response<T>;
  readonly status: number;

  constructor({ config, response, status }: { config: RequestConfigFull; response?: Response<T>; status?: number }) {
    super(
      `${config.method} ${config.url} failed with status ${status ?? response?.status ?? RequestError.UNKNOWN_ERROR}`,
    );
    this.config = config;
    this.response = response && typeof status === 'number' ? { ...response, status } : response;
    this.status = status ?? response?.status ?? RequestError.UNKNOWN_ERROR;
  }

  get isNetworkError(): boolean {
    return this.status === RequestError.NETWORK_ERROR;
  }
}

export class InvalidApiUrlError extends Error {
  readonly name = 'InvalidApiUrlError';

  constructor(message?: string | undefined) {
    super(message);
  }
}

export class InvalidGlobalApiUrlError extends Error {
  readonly name = 'InvalidGlobalApiUrlError';

  constructor(message?: string | undefined) {
    super(message);
  }
}

export class MissingAccessTokenError extends Error {
  readonly name = 'MissingAccessTokenError';

  constructor(message?: string | undefined) {
    super(message);
  }
}

export class MissingTokenProviderError extends Error {
  readonly name = 'MissingTokenProviderError';

  constructor(message?: string | undefined) {
    super(message);
  }
}

export type HttpRequestErrorCtx = Record<string, number | object | string>;

export interface OnHttpRequestErrorMethod {
  (error: unknown, ctx: HttpRequestErrorCtx): void;
}
